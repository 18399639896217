import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import moc from "../assets/images/banner-moc-1-1.png"

const BannerOne = () => {
  return (
    <section className="banner-style-one" id="banner">
      <span className="bubble-1"></span>
      <span className="bubble-2"></span>
      <span className="bubble-3"></span>
      <span className="bubble-4"></span>
      <span className="bubble-5"></span>
      <span className="bubble-6"></span>
      <img src={moc} className="banner-mock" alt="Awesome alter Text" />
      <Container>
        <Row>
          <Col xl={6} lg={8}>
            <div className="content-block">
              <h3>
                First-ever<br/>AI-powered App<br/> For One Piece<br/> Trading Cards
              </h3>
              <p>
              Deck Detect is a cutting-edge app designed for trading card<br/>
              enthusiasts, making it easier than ever to collect and organize<br/>
              cards. With its AI-powered card detection feature, users can instantly<br/>
              identify and catalog their cards just by snapping a photo.
              </p>
              <div className="button-block">
                {/* <a href="#none" className="banner-btn">
                  <i className="fa fa-play"></i>
                  Get in<span>Google Play</span>
                </a> */}
                <a href="https://apps.apple.com/us/app/deck-detect/id6476965574" className="banner-btn">
                  <i className="fa fa-apple"></i>
                  Get in<span>Play Store</span>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default BannerOne
